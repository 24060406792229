<template>
  <v-chart class="chart" :option="option" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  props: {
    dataItems: {
      required: true,
    },
    title: {
      default: undefined,
      required: false,
    },
    subTitle: {
      default: "",
      required: false,
    },
  },
  components: {
    VChart,
  },
  computed: {
    option() {
      let options = {
        title: {
          text: this.title,
          subtext: this.subTitle,
          left: "center",
          bottom: "0",
          show: this.title != undefined,
        },
        tooltip: {
          trigger: "item",
          valueFormatter: (value) => this.$options.filters.toCurrency(value),
        },
        series: [
          {
            type: "pie",
            radius: "67%",
            labelLine: {
              show: false,
            },
            label: {
              position: "inner",
            },
            data: this.dataItems,
            
          },
        ],
      };

      return options;
    },
  },
};
</script>

<style>
</style>