import ResourceService from "../base/resource.service";
export default class SimulationsService extends ResourceService {
  constructor() {
    super('economics/simulations')
  }

  async getDistributionSimulation()
  {
    const uri = `distribution-simulation`;
    return await this.get(uri);
  }
}


