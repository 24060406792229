<template>
  <div>
    <transition name="fade" mode="out-in">
      <div class="d-flex justify-content-center mt-2 mb-2" v-if="isLoading">
        <b-spinner />
      </div>
      <div v-else>
        <b-card>
          <b-card-title>Infocard</b-card-title>
          <div>
            <treeselect
              :multiple="false"
              :options="locationsInfocardsList"
              :disable-branch-nodes="true"
              :show-count="true"
              search-nested
              v-model="infocardId"
            />
          </div>
        </b-card>
        <b-card
          style="height: 600px; width: 100%"
          v-if="simulationData != null && infocardId"
        >
          <side-bar-chart :dataItems="option"></side-bar-chart>
        </b-card>
        <b-card v-if="simulationData != null && infocardId">
          <b-tabs>
            <b-tab
              v-for="(alternative) in simulationData.alternatives"
              :key="alternative.id"
            >
              <template #title>
                <alternative-tab-title v-model="alternative.title" />
              </template>
              <alternative-simulation-tab
                :simulation-data="simulationData"
                :alternative-data="alternative"
                :infocard-id="infocardId"
                @delete-simulation="handleDeleteSimulation(alternative.id)"
                @update-simulation="handlesAlternativeChange(alternative)"
              />
            </b-tab>
            <template #tabs-end>
              <b-nav-item role="presentation" @click.prevent="newTab" href="#"
                ><b>+</b></b-nav-item
              >
            </template>
            <template #empty>
              <div class="text-center text-muted mb-2">
                <strong>No tabs</strong><br />
                Open a new simulation using the <b>+</b> button above.
              </div>
            </template>
          </b-tabs>
        </b-card>
      </div>
    </transition>
  </div>
</template>

<script>
import InfocardSimulationService from "@/services/economics/infocard-simulation.service";
import AlternativeSimulationTab from "./AlternativeSimulationTab";
import AlternativeTabTitle from "./AlternativeTabTitle";
import {
  BCard,
  BCardTitle,
  BButton,
  BTabs,
  BTab,
  BNavItem,
  BSpinner,
} from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { uuid } from "vue-uuid";
import SideBarChart from "@/components/charts/SideBarChart.vue";
export default {
  components: {
    BCard,
    BCardTitle,
    BButton,
    BTabs,
    BTab,
    BNavItem,
    AlternativeSimulationTab,
    AlternativeTabTitle,
    Treeselect,
    BSpinner,
    SideBarChart,
  },
  data() {
    return {
      infocardId: null,
      isLoading: false,
      simulationData: null,
      alternativGraphOptions: [],
    };
  },
  async created() {
    this.isLoading = true;
    await this.fetchLocations({ include: ["infocards"] });
    this.isLoading = false;
  },
  computed: {
    ...mapGetters({ locationsInfoCards: "locationsOld/list" }),
    locationsInfocardsList() {
      return this.locationsInfoCards.map((l) => {
        return {
          id: l.id,
          label: l.name,
          children: l.infoCards.map((c) => {
            return {
              id: c.id,
              label: c.name,
            };
          }),
        };
      });
    },
    option() {
      let grafData = [];
      if (this.simulationData == null) return grafData;

      grafData.push([
        this.getGrafText(
          "BaseLine",
          this.$options.filters.toCurrency(this.simulationData.totalCost),
          this.$options.filters.toCurrency(this.simulationData.costPrTon),
          0
        ),
        this.simulationData.totalCost,
      ]);

      this.alternativGraphOptions.forEach((element) => {
        if (element.totalCost <= 0) return;
        grafData.push([
          this.getGrafText(
            element.title,
            this.$options.filters.toCurrency(element.totalCost),
            this.$options.filters.toCurrency(element.costPrTon),
            this.$options.filters.toCurrency(element.percentageDiff)
          ),
          element.totalCost,
        ]);
      });

      return grafData;
    },
  },
  methods: {
    ...mapActions({
      fetchLocations: "locationsOld/fetchList",
    }),

    getGrafText(name, total, ton, percent) {
      return `${name} \n\n Total udgift: ${total} \n\nTonspris: ${ton} \n\n +/- %: ${percent}`;
    },
    async newTab() {
      var simulation = {
        id: uuid.v4(),
        title: "New alternative",
        pricePrCollection: 0,
        monthlyRent: 0,
        chargePrTons: 0,
        incomePrTons: 0,
        tonsCollected:
          this.simulationData != null
            ? Math.round(this.simulationData.tonsPrCollection * 100) / 100
            : 0,
      };
      try {
        const service = new InfocardSimulationService();
        await service.addSimulation(this.infocardId, simulation);

        this.simulationData.alternatives.push(simulation);
      } catch {}
    },
    async handleDeleteSimulation(simulationId) {
      try {
        const service = new InfocardSimulationService();
        await service.removeSimulation(this.infocardId, simulationId);
        this.simulationData.alternatives.splice(
          this.simulationData.alternatives.indexOf(
            this.simulationData.alternatives.find((i) => i.id == simulationId)
          ),
          1
        );
        let index = this.alternativGraphOptions.findIndex(
          (e) => e.id == simulationId
        );
        if (index > -1) {
          this.alternativGraphOptions.splice(index, 1);
        }
      } catch {}
    },
    handlesAlternativeChange(alternative) {
      let index = this.alternativGraphOptions.findIndex(
        (e) => e.id == alternative.id
      );
      if (index > -1) {
        this.alternativGraphOptions.splice(index, 1);
        this.alternativGraphOptions.splice(index, 0, alternative);
      } else {
        this.alternativGraphOptions.push(alternative);
      }
    },
  },
  watch: {
    async infocardId(newValue) {
      if (newValue == null) return (this.simulationData = null);
      const service = new InfocardSimulationService();
      this.simulationData = await service.getSimulationForInfocard(newValue);
    },
  },
};
</script>
