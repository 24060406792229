<template>
  <v-chart class="chart" :option="option" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  props: {
    dataItems: {
      required: true,
    },
  },
  components: {
    VChart,
  },
  computed: {
    option() {
      let options = {
        dataset: {
          source: this.dataItems,
        },
        xAxis: {},
        yAxis: {
          type: "category",
        },
        series: [
          {
            type: "bar",
            itemStyle: {
              color: "#407e3d",
            },
          },
        ],
      };

      return options;
    },
  },
};
</script>

<style>
</style>