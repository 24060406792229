import { data } from "jquery";
import BaseService from "../base/base.service";

export default class InfocardSimulationService extends BaseService {
    constructor() {
        super('economics/simulations/infocard-simulation/')
    }

    async getSimulationForInfocard(infocardId)
    {
        return (await this.get(infocardId)).data.data;
    }

    async addSimulation(infocardId, simulation)
    {
        return await this.post(infocardId, simulation);
    }

    async removeSimulation(infocardId, simulationId)
    {
        return await this.delete(`${infocardId}/${simulationId}`);
    }
    
    async updateSimulation(infocardId, simulation)
    {
        return await this.patch(`${infocardId}/${simulation.id}`, simulation);
    }
}