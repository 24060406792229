<template>
  <strong class="to-hover d-flex justify-content-between align-items-center">
    <input
      v-if="edit"
      type="text"
      v-model="title"
      ref="titleInput"
      @blur="handleEditDone"
      @keyup.enter="handleEditDone"
    />
    <span v-else>{{ title }}</span>
    <feather-icon
      @click="edit = true"
      class="to-show"
      icon="Edit2Icon"
      size="12"
    />
  </strong>
</template>
<script>
export default {
  props: {
    value: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      edit: false,
      title: this.value,
    };
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.title);
    },
    handleEditDone() {
      this.edit = false;
      this.handleInput();
    },
  },
  watch: {
    edit(newValue) {
      if (!newValue) return;
      this.$nextTick(function () {
        this.$refs.titleInput.focus();
      });
    },
  },
};
</script>

<style scoped>
svg {
  margin-left: 5px;
}
</style>