<template>
  <div>
    <confirm-modal :topClass="true" ref="confirmModal" />
    <loader class="loader" ref="loader"></loader>
    <div class="d-flex">
      <b-table :fields="tableFields" :items="tableContent">
        <template #cell(baseline)="data">
          {{ data.value | toCurrency }}
        </template>
        <template #cell(baseline1)="data">
          {{ data.value | toCurrency }}
        </template>
        <template #cell(alternative)="data">
          <b-form-input
            type="number"
            size="sm"
            v-if="data.value.type == 'INPUT'"
            v-model="alternativeData[data.value.dataKey]"
            @change="alternativeDataChange()"
          />
          <span v-else>
            <span v-if="data.value.dataKey == 'totalCollectionCost'">{{
              totalCollectionCostSimulated | toCurrency
            }}</span>
            <span v-else-if="data.value.dataKey == 'totalRentCost'">{{
              totalRentCostSimulated | toCurrency
            }}</span>
            <span v-if="data.value.dataKey == 'totalChargeCost'">{{
              totalChargeCostSimulated | toCurrency
            }}</span>
            <span v-if="data.value.dataKey == 'totalIncome'"
              >-{{ totalIncomeSimulated | toCurrency }}</span
            >
          </span>
        </template>
        <template #cell(alternative1)="data">
          <b-form-input
            type="number"
            size="sm"
            v-if="data.value.type == 'INPUT'"
            v-model="alternativeData[data.value.dataKey]"
            @change="alternativeDataChange()"
          />
          <span v-else>
            <span v-if="data.value.dataKey == 'totalCollectionCost'">{{
              totalCollectionCostSimulated
            }}</span>
            <span v-else-if="data.value.dataKey == 'collectionCount'">{{
              collectionCountSimulated | toCurrency
            }}</span>
            <span v-else-if="data.value.dataKey == 'totalTonsCollected'">{{
              totalTonsCollectedSimulated | toCurrency
            }}</span>
            <span v-else-if="data.value.dataKey == 'costPrCollection'">{{
              costPrCollectionSimulated | toCurrency
            }}</span>
            <span v-else-if="data.value.dataKey == 'totalCost'">{{
              totalCostSimulated | toCurrency
            }}</span>
            <span v-else-if="data.value.dataKey == 'costPrTon'">{{
              costPrTonSimulated | toCurrency
            }}</span>
            <span v-else-if="data.value.dataKey == 'procentageDif'"
              >{{ procentageDifSimulated | toCurrency }}%</span
            >
          </span>
        </template>
      </b-table>
    </div>
    <div class="mt-1 d-flex justify-content-between">
      <b-button variant="primary" @click="updateSimulation">Save</b-button>
      <b-button variant="danger" @click="handleRemove">
        Remove alternative
      </b-button>
    </div>
  </div>
</template>

<script>
import InfocardSimulationService from "@/services/economics/infocard-simulation.service";
import { BTable, BButton, BFormInput } from "bootstrap-vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import loader from "@/components/layout/loader.vue";
export default {
  props: {
    simulationData: {
      required: true,
    },
    alternativeData: {
      required: true,
    },
    infocardId: {
      required: true,
    },
  },
  components: {
    BTable,
    BButton,
    ConfirmModal,
    BFormInput,
    loader,
  },
  data() {
    return {
      tableFields: [
        {
          key: "categories",
          label: "",
          variant: "light",
        },
        {
          key: "baseline",
          label: "Baseline",
        },
        {
          key: "alternative",
          label: "Alternative",
        },
        {
          key: "categories1",
          label: "",
          variant: "light",
        },
        {
          key: "baseline1",
          label: "Baseline",
        },
        {
          key: "alternative1",
          label: "Alternative",
        },
      ],
      tableContent: [
        {
          categories: "Pris/Tøm",
          baseline: this.simulationData.pricePrCollection,
          alternative: {
            type: "INPUT",
            dataKey: "pricePrCollection",
          },
          categories1: "Tømninger",
          baseline1: this.simulationData.collectionCount,
          alternative1: {
            type: "CALCULATION",
            dataKey: "collectionCount",
          },
        },
        {
          categories: "Leje/mdr",
          baseline: this.simulationData.monthlyRent,
          alternative: {
            type: "INPUT",
            dataKey: "monthlyRent",
          },
          categories1: "Tons total",
          baseline1: this.simulationData.totalTonsCollected,
          alternative1: {
            type: "CALCULATION",
            dataKey: "totalTonsCollected",
          },
        },
        {
          categories: "Afgift/ton",
          baseline: this.simulationData.chargePrTon,
          alternative: {
            type: "INPUT",
            dataKey: "chargePrTons",
          },
          categories1: "Tons/Tøm",
          baseline1: this.simulationData.tonsPrCollection,
          alternative1: {
            type: "INPUT",
            dataKey: "tonsCollected",
          },
        },
        {
          categories: "Indtægt/ton",
          baseline: this.simulationData.incomePrTon,
          alternative: {
            type: "INPUT",
            dataKey: "incomePrTons",
          },
          categories1: "Tonspris",
          baseline1: this.simulationData.costPrTon,
          alternative1: {
            type: "CALCULATION",
            dataKey: "costPrTon",
          },
        },
        {
          categories: "Tøm total",
          baseline: this.simulationData.totalCollectionCost,
          alternative: {
            type: "CALCULATION",
            dataKey: "totalCollectionCost",
          },
          categories1: "Udgift/tøm",
          baseline1: this.simulationData.costPrCollection,
          alternative1: {
            type: "CALCULATION",
            dataKey: "costPrCollection",
          },
        },
        {
          categories: "Leje total",
          baseline: this.simulationData.totalRentCost,
          alternative: {
            type: "CALCULATION",
            dataKey: "totalRentCost",
          },
          categories1: "Total udgift",
          baseline1: this.simulationData.totalCost,
          alternative1: {
            type: "CALCULATION",
            dataKey: "totalCost",
          },
        },
        {
          categories: "Afgift total",
          baseline: this.simulationData.totalChargeCost,
          alternative: {
            type: "CALCULATION",
            dataKey: "totalChargeCost",
          },
          categories1: "",
          baseline1: "",
          alternative1: "",
        },
        {
          categories: "Indtægt total",
          baseline: this.simulationData.totalIncome,
          alternative: {
            type: "CALCULATION",
            dataKey: "totalIncome",
          },

          categories1: "Procent forskel",
          baseline1: "",
          alternative1: {
            type: "CALCULATION",
            dataKey: "procentageDif",
          },
        },
      ],
    };
  },
  computed: {
    totalCollectionCostSimulated() {
      return (
        this.alternativeData.pricePrCollection * this.collectionCountSimulated
      );
    },
    totalRentCostSimulated() {
      return this.alternativeData.monthlyRent * 12;
    },
    totalChargeCostSimulated() {
      return (
        this.alternativeData.chargePrTons *
        this.simulationData.totalTonsCollected
      );
    },
    totalIncomeSimulated() {
      return (
        this.alternativeData.incomePrTons *
        this.simulationData.totalTonsCollected
      );
    },
    collectionCountSimulated() {
      return this.alternativeData.tonsCollected > 0
        ? this.totalTonsCollectedSimulated / this.alternativeData.tonsCollected
        : 0;
    },
    totalTonsCollectedSimulated() {
      return this.simulationData.totalTonsCollected;
    },
    costPrTonSimulated() {
      return this.calculatecostPrTonSimulated();
    },
    costPrCollectionSimulated() {
      return this.collectionCountSimulated > 0
        ? this.totalCostSimulated / this.collectionCountSimulated
        : 0;
    },
    totalCostSimulated() {
      return this.calculatetotalCostSimulated();
    },
    procentageDifSimulated() {
      return this.calculateprocentageDifSimulated();
    },
  },
  mounted() {
    this.alternativeDataChange();
  },
  methods: {
    calculatetotalCostSimulated() {
      let result =
        this.totalCollectionCostSimulated +
        this.totalRentCostSimulated +
        this.totalChargeCostSimulated -
        this.totalIncomeSimulated;
      this.alternativeData.totalCost = result;
      return result;
    },
    calculatecostPrTonSimulated() {
      let result = this.totalCostSimulated / this.totalTonsCollectedSimulated;
      this.alternativeData.costPrTon = result;
      return result;
    },
    calculateprocentageDifSimulated() {
      var percentageDiff =
        ((this.simulationData.totalCost - this.totalCostSimulated) /
          this.simulationData.totalCost) *
        100;

      if (this.simulationData.totalCost > this.totalCostSimulated)
        percentageDiff = percentageDiff * -1;
      else percentageDiff = Math.abs(percentageDiff);

      this.alternativeData.percentageDiff = percentageDiff;

      return percentageDiff;
    },
    async handleRemove() {
      if (!(await this.$refs.confirmModal.confirm())) return;
      this.$emit("delete-simulation");
    },
    async updateSimulation() {
      try {
        this.$refs.loader.loading();
        const service = new InfocardSimulationService();
        await service.updateSimulation(this.infocardId, this.alternativeData);
        this.$refs.loader.loadCompelete();
      } catch {}
    },
    alternativeDataChange() {
      this.$emit("update-simulation");
    },
  },
  watch: {},
};
</script>
<style lang="scss">
table {
  border: 1px solid #ebe9f1;
  font-size: 12px;
}
td {
  border-right: 1px solid #ebe9f1;
  border-left: 1px solid #ebe9f1;
}
.loader {
  position: absolute;
  top: 19px;
  right: 22px;
}
</style>