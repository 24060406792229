<template>
  <div>
    <b-tabs
      content-class="col-12 col-md-9 mt-1 mt-md-0 tab-content-container"
      pills
      nav-wrapper-class="background-nav"
    >
      <b-tab @click="active = 'DISTRIBUTION_SIMULATOR'">
        <template #title>
          {{ $t("Simulations.Navs.DistributionSimulator") }}
        </template>
      </b-tab>
      <b-tab @click="active = 'INFOCARD_SIMULATOR'">
        <template #title>
          {{ $t("Simulations.Navs.InfocardSimulator") }}
        </template>
      </b-tab>
    </b-tabs>
    <distribution-simulator-tab v-if="active == 'DISTRIBUTION_SIMULATOR'" />
    <infocard-simulator v-else-if="active == 'INFOCARD_SIMULATOR'" />
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import DistributionSimulatorTab from './subviews/DistributionSimulatorTab.vue';
import InfocardSimulator from './infocardSimulations/InfocardSimulatorIndex.vue';

export default {
  components: {
    BTabs,
    BTab,
    DistributionSimulatorTab,
    InfocardSimulator,
  },
  data() {
    return {
      active: "DISTRIBUTION_SIMULATOR",
      activeLoading: false,
      type: "border",
    };
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>