<template>
  <div>
    <transition name="fade" mode="out-in">
      <div class="d-flex justify-content-center mt-2 mb-2" v-if="isLoading">
        <b-spinner />
      </div>
      <div style="padding-bottom: 10px" v-else>
        <b-card>
          <b-card-title>
            {{ $t("Simulations.DistributionSimulator.Current") }}
          </b-card-title>
          <b-row>
            <b-col cols="6">
              <b-table-lite
                fixed
                hover
                :fields="tableFields"
                :items="currentTableContent"
              >
                <template #cell(category)="data">
                  <small>
                    <svg
                      v-if="data.value == 'WasteCategoryReuse'"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12" r="12" fill="#438651" />
                      <path
                        d="M16.2177 13.2099L16.8544 7.34195C16.9268 6.67428 16.3336 6.12642 15.6738 6.2516L10.2192 7.28644C6.15926 8.05669 4.69404 13.1934 7.31485 16.21C8.04194 14.5733 9.56351 12.242 11.7998 10.6437C9.8016 14.487 9.13874 16.0096 8.93893 17.4813C12.0015 19.0249 15.8083 16.9832 16.2177 13.2099Z"
                        fill="white"
                      />
                    </svg>
                    <svg
                      v-else-if="data.value == 'WasteCategoryRezycl'"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12" r="12" fill="#3C7A49" />
                      <path
                        d="M13.9695 10.7258H16.9999V7.73099"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7 8.60812C7.51591 8.09828 8.12838 7.69386 8.80244 7.41794C9.47651 7.14201 10.199 7 10.9286 7C11.6582 7 12.3806 7.14201 13.0547 7.41794C13.7288 7.69386 14.3412 8.09828 14.8571 8.60812L17 10.7258"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.0305 13.2551H7V16.2499"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17 15.3728C16.4841 15.8826 15.8716 16.2871 15.1976 16.563C14.5235 16.8389 13.801 16.9809 13.0714 16.9809C12.3418 16.9809 11.6194 16.8389 10.9453 16.563C10.2712 16.2871 9.65876 15.8826 9.14286 15.3728L7 13.2552"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      v-else-if="data.value == 'WasteCategoryCombostion'"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12" r="12" fill="#305E38" />
                      <path
                        d="M10.5 10.8L8.34886 9.14263C7.58199 10.3458 7 11.7647 7 13.3C7 14.6261 7.52678 15.8978 8.46447 16.8355C9.40215 17.7732 10.6739 18.3 12 18.3C13.3261 18.3 14.5979 17.7732 15.5355 16.8355C16.4732 15.8978 17 14.6261 17 13.3C17 8.79999 12.5 5.29999 12.5 5.29999L10.5 10.8Z"
                        fill="white"
                      />
                    </svg>
                    <svg
                      v-else-if="data.value == 'WasteCategorySpecial'"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12" r="12" fill="#234328" />
                      <path
                        d="M10 11H12V18"
                        stroke="white"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 8C12.8284 8 13.5 7.32843 13.5 6.5C13.5 5.67157 12.8284 5 12 5C11.1716 5 10.5 5.67157 10.5 6.5C10.5 7.32843 11.1716 8 12 8Z"
                        fill="white"
                      />
                    </svg>
                    <svg
                      v-else-if="data.value == 'WasteCategoryLandfill'"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12" r="12" fill="#16291A" />
                      <path
                        d="M17.9783 15C18.1817 15 18.2999 14.7702 18.1817 14.6047L13.6675 8.28481C13.5678 8.14522 13.3604 8.14522 13.2607 8.28481L11.4053 10.8824L12.4641 13L11.147 11.2439L11.1405 11.2531L9.66468 9.26958C9.56471 9.13522 9.36349 9.13522 9.26353 9.26958L5.29703 14.6008C5.17433 14.7657 5.29204 15 5.49761 15H17.9783Z"
                        fill="white"
                      />
                    </svg>
                    <span> {{ $t("Generic." + data.value) }}</span>
                  </small>
                </template>
                <template #cell(share)="data">
                  <div class="form-control-sm">
                    {{ data.value | toCurrency }}%
                  </div>
                </template>
                <template #cell(tonsPrice)="data">
                  {{ data.value | toCurrency }}
                </template>
                <template #cell(tonsCO2)="data">
                  {{ data.value | toCurrency }}
                </template>
              </b-table-lite>
            </b-col>
            <b-col cols="6">
              <div class="d-flex justify-content-between" style="height: 100%">
                <pie-chart
                  :title="currentTonsPriceChartTitle"
                  :dataItems="currentTonsPriceChart"
                  :subTitle="$t('Generic.TonsPriceChartTitle')"
                ></pie-chart>
                <pie-chart
                  :title="currentTonsPriceCo2Title"
                  :dataItems="currentTonsCo2Chart"
                  :subTitle="$t('Generic.TonsCO2ChartTitle')"
                ></pie-chart>
              </div>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-card-title>
            {{ $t("Simulations.DistributionSimulator.NewDistribution") }}
          </b-card-title>
          <b-card-title
            v-if="
              (totalForecastShare > 100 || totalForecastShare < 100) &&
              hasChanged
            "
            class="d-flex align-items-center"
          >
            <span class="text-warning mr-1">{{
              totalForecastShare > 100
                ? $t(
                    "Simulations.DistributionSimulator.ForcastShareWarrningMax"
                  )
                : $t(
                    "Simulations.DistributionSimulator.ForcastShareWarrningMin"
                  )
            }}</span>

            <feather-icon color="#ff9f43" size="20" icon="AlertTriangleIcon" />
          </b-card-title>

          <b-row>
            <b-col cols="6">
              <b-table-lite
                fixed
                hover
                :fields="tableFields"
                :items="forcastTableContent"
              >
                <template #cell(category)="data">
                  <small>
                    <svg
                      v-if="data.value == 'WasteCategoryReuse'"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12" r="12" fill="#438651" />
                      <path
                        d="M16.2177 13.2099L16.8544 7.34195C16.9268 6.67428 16.3336 6.12642 15.6738 6.2516L10.2192 7.28644C6.15926 8.05669 4.69404 13.1934 7.31485 16.21C8.04194 14.5733 9.56351 12.242 11.7998 10.6437C9.8016 14.487 9.13874 16.0096 8.93893 17.4813C12.0015 19.0249 15.8083 16.9832 16.2177 13.2099Z"
                        fill="white"
                      />
                    </svg>
                    <svg
                      v-else-if="data.value == 'WasteCategoryRezycl'"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12" r="12" fill="#3C7A49" />
                      <path
                        d="M13.9695 10.7258H16.9999V7.73099"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7 8.60812C7.51591 8.09828 8.12838 7.69386 8.80244 7.41794C9.47651 7.14201 10.199 7 10.9286 7C11.6582 7 12.3806 7.14201 13.0547 7.41794C13.7288 7.69386 14.3412 8.09828 14.8571 8.60812L17 10.7258"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.0305 13.2551H7V16.2499"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17 15.3728C16.4841 15.8826 15.8716 16.2871 15.1976 16.563C14.5235 16.8389 13.801 16.9809 13.0714 16.9809C12.3418 16.9809 11.6194 16.8389 10.9453 16.563C10.2712 16.2871 9.65876 15.8826 9.14286 15.3728L7 13.2552"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      v-else-if="data.value == 'WasteCategoryCombostion'"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12" r="12" fill="#305E38" />
                      <path
                        d="M10.5 10.8L8.34886 9.14263C7.58199 10.3458 7 11.7647 7 13.3C7 14.6261 7.52678 15.8978 8.46447 16.8355C9.40215 17.7732 10.6739 18.3 12 18.3C13.3261 18.3 14.5979 17.7732 15.5355 16.8355C16.4732 15.8978 17 14.6261 17 13.3C17 8.79999 12.5 5.29999 12.5 5.29999L10.5 10.8Z"
                        fill="white"
                      />
                    </svg>
                    <svg
                      v-else-if="data.value == 'WasteCategorySpecial'"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12" r="12" fill="#234328" />
                      <path
                        d="M10 11H12V18"
                        stroke="white"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 8C12.8284 8 13.5 7.32843 13.5 6.5C13.5 5.67157 12.8284 5 12 5C11.1716 5 10.5 5.67157 10.5 6.5C10.5 7.32843 11.1716 8 12 8Z"
                        fill="white"
                      />
                    </svg>
                    <svg
                      v-else-if="data.value == 'WasteCategoryLandfill'"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12" r="12" fill="#16291A" />
                      <path
                        d="M17.9783 15C18.1817 15 18.2999 14.7702 18.1817 14.6047L13.6675 8.28481C13.5678 8.14522 13.3604 8.14522 13.2607 8.28481L11.4053 10.8824L12.4641 13L11.147 11.2439L11.1405 11.2531L9.66468 9.26958C9.56471 9.13522 9.36349 9.13522 9.26353 9.26958L5.29703 14.6008C5.17433 14.7657 5.29204 15 5.49761 15H17.9783Z"
                        fill="white"
                      />
                    </svg>
                    <span> {{ $t("Generic." + data.value) }}</span>
                  </small>
                </template>
                <template #cell(share)="data">
                  <b-form-input
                    style="width: 70px"
                    size="sm"
                    @blur="calculateForcast(data.item)"
                    type="text"
                    v-model="data.item.share"
                  />
                </template>
                <template #cell(tonsPrice)="data">
                  {{ data.value | toCurrency }}
                </template>
                <template #cell(tonsCO2)="data">
                  {{ data.value | toCurrency }}
                </template>
              </b-table-lite>
            </b-col>
            <b-col cols="6">
              <div class="d-flex justify-content-between" style="height: 100%">
                <pie-chart
                  :title="forcastTonsPriceTitle"
                  :dataItems="forcastTonsPriceChart"
                  :subTitle="$t('Generic.TonsPriceChartTitle')"
                ></pie-chart>
                <pie-chart
                  :title="forcastTonsCo2ChartTitle"
                  :dataItems="forcastTonsCo2Chart"
                  :subTitle="$t('Generic.TonsCO2ChartTitle')"
                ></pie-chart>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </transition>
  </div>
</template>
<script>
import {
  BCard,
  BCardTitle,
  BRow,
  BCol,
  BTableLite,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";
import SimulationsService from "@/services/economics/simulations.service";
import PieChart from "@/components/charts/PieChart.vue";
export default {
  components: {
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BTableLite,
    BSpinner,
    PieChart,
    BFormInput,
  },
  async created() {
    try {
      const service = new SimulationsService();
      this.isLoading = true;
      let response = await service.getDistributionSimulation();
      this.data = response.data.data;
      this.forcastData = JSON.parse(JSON.stringify(response.data.data));
    } finally {
      this.isLoading = false;
    }
  },
  data() {
    return {
      isLoading: false,
      data: null,
      hasChanged: false,
      forcastData: null,
      tableFields: [
        {
          key: "category",
          label: this.$t("Generic.Category"),
        },
        {
          key: "share",
          label: this.$t("Generic.Share"),
        },
        {
          key: "tonsPrice",
          label: this.$t("Generic.TonsPrice"),
        },
        {
          key: "tonsCO2",
          label: this.$t("Generic.TonsCO2"),
        },
      ],
      noCatColor: "#ccc",
      reuseColor: "#438651",
      rezyclColor: "#3c7a49",
      combostionColor: "#305e38",
      specialColor: "#234328",
      landfillColor: "#16291a",
    };
  },
  computed: {
    currentTableContent() {
      return [
        {
          category: "WithoutCategory",
          _cellVariants: { category: "without-category" },
          share: this.calcPercentageOf(
            this.data.withoutCategoryTons,
            this.data.totalTons
          ),
          tonsPrice: this.divedeOut(
            this.data.withoutCategoryExpenses,
            this.data.withoutCategoryTons
          ),
          tonsCO2: 0,
        },
        {
          category: "WasteCategoryReuse",
          _cellVariants: { category: "reuse" },
          share: this.calcPercentageOf(
            this.data.reuseTons,
            this.data.totalTons
          ),
          tonsPrice: this.divedeOut(
            this.data.reuseExpenses,
            this.data.reuseTons
          ),
          tonsCO2: this.data.reuseCO2,
        },
        {
          category: "WasteCategoryRezycl",
          _cellVariants: { category: "rezycl" },
          share: this.calcPercentageOf(
            this.data.rezyclTons,
            this.data.totalTons
          ),
          tonsPrice: this.divedeOut(
            this.data.rezyclExpenses,
            this.data.rezyclTons
          ),
          tonsCO2: this.data.rezyclCO2,
        },
        {
          category: "WasteCategoryCombostion",
          _cellVariants: { category: "combostion" },
          share: this.calcPercentageOf(
            this.data.combostionTons,
            this.data.totalTons
          ),
          tonsPrice: this.divedeOut(
            this.data.combostionExpenses,
            this.data.combostionTons
          ),
          tonsCO2: this.data.combostionCO2,
        },
        {
          category: "WasteCategorySpecial",
          _cellVariants: { category: "special" },
          share: this.calcPercentageOf(
            this.data.specialTons,
            this.data.totalTons
          ),
          tonsPrice: this.divedeOut(
            this.data.specialExpenses,
            this.data.specialTons
          ),
          tonsCO2: this.data.specialCO2,
        },
        {
          category: "WasteCategoryLandfill",
          _cellVariants: { category: "landfill" },
          share: this.calcPercentageOf(
            this.data.landfillTons,
            this.data.totalTons
          ),
          tonsPrice: this.divedeOut(
            this.data.landfillExpenses,
            this.data.landfillTons
          ),
          tonsCO2: this.data.landfillCO2,
        },
      ];
    },
    currentTonsPriceCo2Title() {
      return this.$options.filters.toCurrency(this.data.totalCO2);
    },
    currentTonsCo2Chart() {
      return [
        new this.getChartItem(
          this.data.reuseTons == 0 ? 0 : this.data.reuseCO2,
          this.$t("Generic.WasteCategoryReuse"),
          this.reuseColor,
          require("@/assets/images/widget/reusesvg.png")
        ),
        new this.getChartItem(
          this.data.rezyclCO2 == 0 ? 0 : this.data.rezyclCO2,
          this.$t("Generic.WasteCategoryRezycl"),
          this.rezyclColor,
          require("@/assets/images/widget/rezycl.png")
        ),
        new this.getChartItem(
          this.data.combostionTons == 0 ? 0 : this.data.combostionCO2,
          this.$t("Generic.WasteCategoryCombostion"),
          this.combostionColor,
          require("@/assets/images/widget/CombostionCategory.png")
        ),
        new this.getChartItem(
          this.data.specialTons == 0 ? 0 : this.data.specialCO2,
          this.$t("Generic.WasteCategorySpecial"),
          this.specialColor,
          require("@/assets/images/widget/SpecialCategory.png")
        ),
        new this.getChartItem(
          this.data.landfillTons == 0 ? 0 : this.data.landfillCO2,
          this.$t("Generic.WasteCategoryLandfill"),
          this.landfillColor,
          require("@/assets/images/widget/LandfillCategory.png")
        ),
      ];
    },
    currentTonsPriceChartTitle() {
      let result = this.divedeOut(this.data.totalExpenses, this.data.totalTons);
      return this.$options.filters.toCurrency(result);
    },
    currentTonsPriceChart() {
      return [
        new this.getChartItem(
          this.data.withoutCategoryExpenses,
          this.$t("Generic.WithoutCategory"),
          this.noCatColor,
          "transparent"
        ),
        new this.getChartItem(
          this.data.reuseTons == 0 ? 0 : this.data.reuseExpenses,
          this.$t("Generic.WasteCategoryReuse"),
          this.reuseColor,
          require("@/assets/images/widget/reusesvg.png")
        ),
        new this.getChartItem(
          this.data.rezyclCO2 == 0 ? 0 : this.data.rezyclExpenses,
          this.$t("Generic.WasteCategoryRezycl"),
          this.rezyclColor,
          require("@/assets/images/widget/rezycl.png")
        ),
        new this.getChartItem(
          this.data.combostionTons == 0 ? 0 : this.data.combostionExpenses,
          this.$t("Generic.WasteCategoryCombostion"),
          this.combostionColor,
          require("@/assets/images/widget/CombostionCategory.png")
        ),
        new this.getChartItem(
          this.data.specialTons == 0 ? 0 : this.data.specialExpenses,
          this.$t("Generic.WasteCategorySpecial"),
          this.specialColor,
          require("@/assets/images/widget/SpecialCategory.png")
        ),
        new this.getChartItem(
          this.data.landfillTons == 0 ? 0 : this.data.landfillExpenses,
          this.$t("Generic.WasteCategoryLandfill"),
          this.landfillColor,
          require("@/assets/images/widget/LandfillCategory.png")
        ),
      ];
    },
    forcastTableContent() {
      return [
        {
          category: "WithoutCategory",
          _cellVariants: { category: "without-category" },
          share: this.$options.filters.toCurrency(
            this.calcPercentageOf(
              this.forcastData.withoutCategoryTons,
              this.forcastData.totalTons
            )
          ),
          co2PrTon: 0,
          tonsPrice: this.divedeOut(
            this.data.withoutCategoryExpenses,
            this.data.withoutCategoryTons
          ),
          tonsCO2: 0,
        },
        {
          category: "WasteCategoryReuse",
          _cellVariants: { category: "reuse" },
          share: this.$options.filters.toCurrency(
            this.calcPercentageOf(
              this.forcastData.reuseTons,
              this.forcastData.totalTons
            )
          ),
          co2PrTon: this.calculateCo2PerTon(
            this.data.reuseTons,
            this.data.reuseCO2
          ),
          tonsPrice: this.divedeOut(
            this.data.reuseExpenses,
            this.data.reuseTons
          ),
          tonsCO2: this.forcastData.reuseCO2,
        },
        {
          category: "WasteCategoryRezycl",
          _cellVariants: { category: "rezycl" },
          share: this.$options.filters.toCurrency(
            this.calcPercentageOf(
              this.forcastData.rezyclTons,
              this.forcastData.totalTons
            )
          ),
          co2PrTon: this.calculateCo2PerTon(
            this.data.rezyclTons,
            this.data.rezyclCO2
          ),
          tonsPrice: this.divedeOut(
            this.data.rezyclExpenses,
            this.data.rezyclTons
          ),
          tonsCO2: this.forcastData.rezyclCO2,
        },
        {
          category: "WasteCategoryCombostion",
          _cellVariants: { category: "combostion" },
          share: this.$options.filters.toCurrency(
            this.calcPercentageOf(
              this.forcastData.combostionTons,
              this.forcastData.totalTons
            )
          ),
          co2PrTon: this.calculateCo2PerTon(
            this.data.combostionTons,
            this.data.combostionTons
          ),
          tonsPrice: this.divedeOut(
            this.data.combostionExpenses,
            this.data.combostionTons
          ),
          tonsCO2: this.forcastData.combostionCO2,
        },
        {
          category: "WasteCategorySpecial",
          _cellVariants: { category: "special" },
          share: this.$options.filters.toCurrency(
            this.calcPercentageOf(
              this.forcastData.specialTons,
              this.forcastData.totalTons
            )
          ),
          co2PrTon: this.calculateCo2PerTon(
            this.data.specialTons,
            this.data.specialCO2
          ),
          tonsPrice: this.divedeOut(
            this.data.specialExpenses,
            this.data.specialTons
          ),
          tonsCO2: this.forcastData.specialCO2,
        },
        {
          category: "WasteCategoryLandfill",
          _cellVariants: { category: "landfill" },
          share: this.$options.filters.toCurrency(
            this.calcPercentageOf(
              this.forcastData.landfillTons,
              this.forcastData.totalTons
            )
          ),
          co2PrTon: this.calculateCo2PerTon(
            this.data.landfillTons,
            this.data.landfillCO2
          ),
          tonsPrice: this.divedeOut(
            this.data.landfillExpenses,
            this.data.landfillTons
          ),
          tonsCO2: this.forcastData.landfillCO2,
        },
      ];
    },
    forcastTonsCo2ChartTitle() {
      let totalt = 0;
      this.forcastTonsCo2Chart.forEach((element) => {
        totalt += element.value;
      });

      return this.$options.filters.toCurrency(totalt);
    },
    forcastTonsCo2Chart() {
      return [
        new this.getChartItem(
          this.forcastData.reuseCO2,
          this.$t("Generic.WasteCategoryReuse"),
          this.reuseColor,
          require("@/assets/images/widget/reusesvg.png")
        ),
        new this.getChartItem(
          this.forcastData.rezyclCO2,
          this.$t("Generic.WasteCategoryRezycl"),
          this.rezyclColor,
          require("@/assets/images/widget/rezycl.png")
        ),
        new this.getChartItem(
          this.forcastData.combostionCO2,
          this.$t("Generic.WasteCategoryCombostion"),
          this.combostionColor,
          require("@/assets/images/widget/CombostionCategory.png")
        ),
        new this.getChartItem(
          this.forcastData.specialCO2,
          this.$t("Generic.WasteCategorySpecial"),
          this.specialColor,
          require("@/assets/images/widget/SpecialCategory.png")
        ),
        new this.getChartItem(
          this.forcastData.landfillCO2,
          this.$t("Generic.WasteCategoryLandfill"),
          this.landfillColor,
          require("@/assets/images/widget/LandfillCategory.png")
        ),
      ];
    },
    forcastTonsPriceTitle() {
      let totalt = 0;
      this.forcastTonsPriceChart.forEach((element) => {
        totalt += element.value;
      });

      return this.$options.filters.toCurrency(totalt / this.data.totalTons);
    },
    forcastTonsPriceChart() {
      return [
        new this.getChartItem(
          this.divedeOut(
            this.data.withoutCategoryExpenses,
            this.data.withoutCategoryTons
          ) * this.forcastData.withoutCategoryTons,
          this.$t("Generic.WithoutCategory"),
          this.noCatColor,
          "transparent"
        ),
        new this.getChartItem(
          this.divedeOut(this.data.reuseExpenses, this.data.reuseTons) *
            this.forcastData.reuseTons,
          this.$t("Generic.WasteCategoryReuse"),
          this.reuseColor,
          require("@/assets/images/widget/reusesvg.png")
        ),
        new this.getChartItem(
          this.divedeOut(this.data.rezyclExpenses, this.data.rezyclTons) *
            this.forcastData.rezyclTons,
          this.$t("Generic.WasteCategoryRezycl"),
          this.rezyclColor,
          require("@/assets/images/widget/rezycl.png")
        ),
        new this.getChartItem(
          this.divedeOut(
            this.data.combostionExpenses,
            this.data.combostionTons
          ) * this.forcastData.combostionTons,
          this.$t("Generic.WasteCategoryCombostion"),
          this.combostionColor,
          require("@/assets/images/widget/CombostionCategory.png")
        ),
        new this.getChartItem(
          this.divedeOut(this.data.specialExpenses, this.data.specialTons) *
            this.forcastData.specialTons,
          this.$t("Generic.WasteCategorySpecial"),
          this.specialColor,
          require("@/assets/images/widget/SpecialCategory.png")
        ),
        new this.getChartItem(
          this.divedeOut(this.data.landfillExpenses, this.data.landfillTons) *
            this.forcastData.landfillTons,
          this.$t("Generic.WasteCategoryLandfill"),
          this.landfillColor,
          require("@/assets/images/widget/LandfillCategory.png")
        ),
      ];
    },
    totalForecastShare() {
      let totalShare = 0;
      this.forcastTableContent.forEach((element) => {
        totalShare += Number(element.share.replace(",", "."));
      });

      return totalShare;
    },
  },
  methods: {
    getChartItem(value, name, color, background, labelformatter = "") {
      let obj = {
        value: value,
        name: name,
        itemStyle: { color: color },
        label: {
          formatter: labelformatter,
          backgroundColor: {
            image: value > 0 ? background : "transparent",
          },
          width: 25,
          height: 25,
        },
      };

      return obj;
    },
    calculateCo2PerTon(tons, co2) {
      if (co2 == 0 || tons == 0) return;

      return co2 / tons;
    },
    calculateForcast(item) {
      this.hasChanged = true;
      let co2PerTon = item.co2PrTon == undefined ? 0 : item.co2PrTon;
      let share = Number(item.share.replace(",", "."));
      let tonsFromShare = (this.data.totalTons / 100) * share;
      let co2FromShare = co2PerTon * tonsFromShare;

      switch (item.category) {
        case "WithoutCategory":
          this.forcastData.withoutCategoryTons = tonsFromShare;
          break;
        case "WasteCategoryReuse":
          this.forcastData.reuseTons = tonsFromShare;
          this.forcastData.reuseCO2 = co2FromShare;
          break;
        case "WasteCategoryRezycl":
          this.forcastData.rezyclTons = tonsFromShare;
          this.forcastData.rezyclCO2 = co2FromShare;
          break;
        case "WasteCategoryCombostion":
          this.forcastData.combostionTons = tonsFromShare;
          this.forcastData.combostionCO2 = co2FromShare;
          break;
        case "WasteCategorySpecial":
          this.forcastData.specialTons = tonsFromShare;
          this.forcastData.specialCO2 = co2FromShare;
          break;
        case "WasteCategoryLandfill":
          this.forcastData.landfillTons = tonsFromShare;
          this.forcastData.landfillCO2 = co2FromShare;
          break;
      }
    },
    calcPercentageOf(input, total) {
      if (input == 0 || total == 0) return 0;
      return (input / total) * 100;
    },
    divedeOut(total, divider) {
      if (total == 0 || divider == 0) return 0;
      return total / divider;
    },
  },
};
</script>

<style lang="scss">
.table-reuse {
  background: #438651;
  color: white;
}
.table-rezycl {
  background: #3c7a49;
  color: white;
}
.table-combostion {
  background: #305e38;
  color: white;
}
.table-special {
  background: #234328;
  color: white;
}
.table-landfill {
  background: #16291a;
  color: white;
}
</style>
